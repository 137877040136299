<template>
    <div class="columns mb-2">

        <div class="column is-4">
            <div class="control">
                <div>
                    <v-select
                        class="no-wrap-select"
                        v-model="item.name"
                        :options="items"
                        :clearable="false"
                        label="name"
                        :get-option-label="(option) => `${option.name}`"
                        placeholder="- wybierz produkt -"
                        @update:modelValue="itemChange($event)"
                        >
                        <template #no-options>
                            Brak wyników
                        </template>
                    </v-select>
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <div class="control">
                    <input type="number" name="quantity" step="0.01" class="input has-text-right" v-model="item.quantity" @input="updateGrossAmount">
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <div class="control">
                    <input type="number" name="buy_price" step="0.01" class="input has-text-right" v-model="item.buy_price" @input="updateGrossAmount">
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <div class="control">
                    <input type="number" name="sell_price" step="0.01" class="input has-text-right" v-model="item.sell_price" disabled>
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <div class="control">
                    <input type="number" step="0.01" class="input has-text-right" v-model="item.gross_amount" disabled>
                </div>
            </div>
        </div>

        <div class="column is-1">
            <div class="control">
                <button class="button is-danger is-rounded is-outlined" style="width: 2.5em" @click="deleteItem"><strong>-</strong></button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'

export default {
    name: 'ItemForm',
    components: {
        vSelect
    },
    props: {
        initialItem: Object
    },
    data() {
        return {
            item: this.initialItem,
            items: []
        }
    },
    mounted() {
        this.getItems()
    },
    methods: {
        getItems() {
            axios
                .get('/api/v1/items/')
                .then(response => {
                    // console.log(response)
                    this.items = response.data.sort((a, b) => a.id - b.id)
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        itemChange() {
            const selectedItemId = this.item.name.id
            const selectedItem = this.items.find((item) => item.id === selectedItemId)

            if (selectedItem) {
                this.item.buy_price = selectedItem.buy_price
                this.item.sell_price = selectedItem.sell_price
                this.item.item_code = selectedItem.item_code
                this.item.source_id = selectedItemId
            }

            this.updateGrossAmount()
        },
        deleteItem() {
            this.$emit('deleteItem', this.item.item_num)
        },
        updateGrossAmount() {
            // Convert strings to numbers and handle precision properly
            const buy_price = parseFloat(this.item.buy_price) || 0
            const quantity = parseFloat(this.item.quantity) || 0
            
            // Calculate gross amount and round to 2 decimal places using mathematical rounding
            const grossAmount = Math.round((buy_price * quantity) * 100) / 100
            
            // Update the item's gross amount
            // console.log(grossAmount)
            this.item.gross_amount = grossAmount
            // console.log(this.item.gross_amount)
            
            // Ensure quantity is also properly formatted
            this.item.quantity = parseFloat(quantity.toFixed(2))
            
            // Emit the update with the sanitized values
            this.$emit('updatePrice', {
                ...this.item,
                quantity: this.item.quantity,
                gross_amount: this.item.gross_amount
            })
        }
    }
}
</script>
<template>
    <div class="page-add-receipt">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/dashboard/receipts">Kwity</router-link></li>
                <li class="is-active"><router-link to="/dashboard/receipts/add-receipt" aria-current="true">Nowy kwit</router-link></li>
            </ul>
        </nav>

        <!-- Modal components -->
        <NewClientModal v-if="showNewClientModal" @submit="addedClient" @close="showNewClientModal = false"/>
        <EditClientModal v-if="showEditClientModal" @submit="addedClient" @close="showEditClientModal = false" :clientID="selectedClientID"/>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Nowy kwit</h1>
            </div>
        </div>

        <div class="columns is-multiline mb-0">
            <div class="column is-2-desktop is-3-tablet is-12-mobile">
                <div class="field">
                    <label>Data kwitu</label>

                    <div class="control">
                        <input type="date" name="receipt_date" class="input" v-model="receipt.date" @change="getReceipts">
                    </div>
                </div>
            </div>
        </div>

        <div class="columns is-multiline mb-0">
            <div class="column is-2-tablet is-12-mobile">
                <div class="field">
                    <label>Numer kwitu</label>

                    <div class="control">
                        <input type="text" class="input" v-model="receipt.receipt_number">
                    </div>
                </div>
            </div>
        </div>

        <div class="columns is-multiline mb-0">
            <div class="column is-3-desktop is-6-tablet is-12-mobile">
                <h2 class="is-size-5 mb-2">Kontrahent</h2>

                <button class="button is-success is-light is-outlined mb-4 mr-4" @click="showNewClientModal = true">+ Nowy kontrahent</button>
                <!-- <button v-if="receipt.client" class="button is-warning is-outlined is-light" @click="openEditClientModal(receipt.client.id)">Edytuj</button> -->
                <br>
                <div class="is-fullwidth mb-2" id="select-client">
                    <v-select
                        ref="clientSelect"
                        v-model="receipt.client"
                        :options="clients"
                        :clearable="false"
                        label="name"
                        :get-option-label="(option) => `${option.name} | ${option.doc_number}`"
                        placeholder="- wybierz kontrahenta -"
                        >
                        <template #no-options>
                            Brak wyników
                        </template>
                    </v-select>
                </div>
            </div>

            <div class="column is-5-desktop is-6-tablet is-12-mobile">
                <br>
                <div class="ml-4 mt-6" v-if="receipt.client">
                    <p><strong>{{ receipt.client.name }}</strong></p>
                    <p><strong>Nr dokumentu:</strong> {{ receipt.client.doc_number }}</p>
                    <!-- <p v-if="receipt.client.email"><strong>Email:</strong> {{ receipt.client.email }}</p> -->
                    <p v-if="receipt.client.address1"><strong>Adres:</strong> {{ receipt.client.address1 }}</p>
                    <!-- <p v-if="receipt.client.zipcode || receipt.client.place">{{ receipt.client.zipcode }} {{ receipt.client.place }}</p> -->
                </div>
            </div>
        </div>

        <div class="columns is-multiline">
            <div class="column is-8-desktop is-12-tablet">
                <h2 class="is-size-5 mb-4">Produkty</h2>

                <div class="columns mb-2">

                    <div class="column is-4">
                        <label>Produkt</label><br>
                    </div>

                    <div class="column is-2">
                        <div class="field">
                            <label>Ilość</label><br>
                            <label>[kg]</label>
                        </div>
                    </div>

                    <div class="column is-2">
                        <div class="field">
                            <label>Cena</label><br>
                            <label>skupu [zł]</label>
                        </div>
                    </div>

                    <div class="column is-2">
                        <div class="field">
                            <label>Cena</label><br>
                            <label>sprzedaży [zł]</label>
                        </div>
                    </div>

                    <div class="column is-2">
                        <div class="field">
                            <label>Wartość</label><br>
                            <label>[zł]</label>
                        </div>
                    </div>
                </div>

                <div class="mb-2">
                    <ItemForm
                        ref="itemForm"
                        v-for="item in receipt.receipt_items"
                        v-bind:key="item.item_num"
                        v-bind:initialItem="item"
                        @updatePrice="updateTotals(item)"
                        @deleteItem="deleteItem(item)"
                    >
                    </ItemForm>
                </div>

                <button class="button is-success is-rounded is-outlined" style="width: 2.5em" @click="addItem">+</button>
            </div>

            <div class="column is-2 is-offset-6 has-text-right">
                <!-- <h2 class="is-size-5 mb-4">Podsumowanie</h2> -->

                <p><strong>Do wypłaty:</strong></p><br>
                <h1 class="title is-4">{{ receipt.gross_amount.toFixed(2) }} zł</h1>
            </div>
        </div>

        <div class="column is-12">
            <button class="button is-success is-light is-outlined mr-4" @click="submitForm">Zapisz</button>
            <button class="button is-dark is-light is-outlined mr-4" @click="getPdf">Zapisz i pobierz PDF</button>
            <button class="button is-danger is-light is-outlined " @click="confirmCancel(receipt)">Anuluj</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

import vSelect from 'vue-select'

const fileDownload = require('js-file-download')

import ItemForm from '@/components/ItemForm.vue'
import NewClientModal from '@/components/NewClientModal.vue'
import EditClientModal from '@/components/EditClientModal.vue'

export default {
    name: 'AddReceipt',
    components: {
        ItemForm,
        NewClientModal,
        EditClientModal,
        vSelect
    },
    data() {
        return {
            receipt: {
                receipt_number: '',
                date: new Date().toISOString().substr(0, 10),
                client: '',
                receipt_items: [
                    {
                        item_num: 0,
                        name: '',
                        buy_price: '',
                        quantity: 1,
                    },
                    {
                        item_num: 0,
                        name: '',
                        buy_price: '',
                        quantity: 1,
                    },
                    {
                        item_num: 0,
                        name: '',
                        buy_price: '',
                        quantity: 1,
                    },
                    {
                        item_num: 0,
                        name: '',
                        buy_price: '',
                        quantity: 1,
                    },
                    {
                        item_num: 0,
                        name: '',
                        buy_price: '',
                        quantity: 1,
                    }
                ],
                receipt_empty_items: [],
                gross_amount: 0,
                total_quantity: 0
            },
            receipts: [],
            clients: [],
            team: {},
            receiptID: '',
            showNewClientModal: false,
            showEditClientModal: false,
            selectedClientID: null,
        }
    },
    async mounted() {
        await this.getClients(),
        await this.getTeam(),
        await this.getReceipts()
    },
    methods: {
        getClients() {
            axios
                .get('/api/v1/clients/')
                .then(response => {
                    this.clients = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        async getReceipts() {
            await axios
                .get('/api/v1/receipts/')
                .then(response => {
                    this.receipts = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            
            try {
                let existingReceipts = []
                for (let i = 0; i < this.receipts.length; i++) {
                    if (this.receipts[i].date === this.receipt.date) {
                        existingReceipts.push(this.receipts[i])
                    }
                }
                const lastReceipt = existingReceipts[0];

                if (lastReceipt) {
                    const lastReceiptNumberParts = lastReceipt.receipt_number.split('/');
                    const nextReceiptNumberPart1 = parseInt(lastReceiptNumberParts[0], 10) + 1;
                    this.receipt.receipt_number = `${nextReceiptNumberPart1}/${this.receipt.date.substring(8,10)}/${this.receipt.date.substring(5,7)}/${this.receipt.date.substring(0,4)}`;
                } else {
                // If no existing receipts for the date, start with 1
                this.receipt.receipt_number = `1/${this.receipt.date.substring(8,10)}/${this.receipt.date.substring(5,7)}/${this.receipt.date.substring(0,4)}`;
                }
            } catch (error) {
                console.log(JSON.stringify(error));
            }
        },
        getTeam() {
            axios
                .get('/api/v1/teams/')
                .then(response => {
                    this.team = response.data[0]
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        formatDate(date) {
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
            return new Date(date).toLocaleDateString(undefined, options)
        },
        addItem() {
            if (this.receipt.receipt_items.length < 15) {
                this.receipt.receipt_items.push({
                    item_num: this.receipt.receipt_items.length,
                    category: '',
                    name: '',
                    buy_price: '',
                    sell_price: '',
                    quantity: 1
                })

                this.$nextTick(() => {
                    // Find the newly added ItemForm's select element and focus on it
                    const selectElement = this.$refs['itemForm'][this.receipt.receipt_items.length - 1].$refs['itemSelect']
                    if (selectElement) {
                        selectElement.focus()
                    }
                })

            } else {
                toast({
                    message: 'Maksymalna ilość produktów na kwicie to 15.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 3000,
                    position: 'bottom-right',
                })
            }
        },
        deleteItem(selectedItem) {
            // Remove the item from the array
            
            const index = this.receipt.receipt_items.indexOf(selectedItem)
            if (index > -1) {
                this.receipt.receipt_items.splice(index, 1)
            }

            // Update the gross amount after deletion
            this.updateTotals()
        },
        updateTotals(changedItem) {
            let gross_amount = 0
            let total_quantity = 0

            for (let i = 0; i < this.receipt.receipt_items.length; i++) {
                // console.log(this.receipt.receipt_items[i].gross_amount)
                if (this.receipt.receipt_items[i].gross_amount === undefined) {
                    gross_amount += 0
                } else {
                    gross_amount += this.receipt.receipt_items[i].gross_amount
                }
                // console.log(gross_amount)
                if (this.receipt.receipt_items[i].quantity === '') {
                    total_quantity += 0
                } else {
                    total_quantity += this.receipt.receipt_items[i].quantity
                }
            }

            this.receipt.gross_amount = Math.round(gross_amount * 100) / 100
            this.receipt.total_quantity = Number(total_quantity.toFixed(2))
        },
        confirmCancel(receipt) {
            const confirmMessage = `Czy na pewno chcesz anluować wprowadzanie kwitu "${receipt.receipt_number}"?`

            if (window.confirm(confirmMessage)) {
                this.$router.push('/dashboard/receipts')
            }
        },
        submitForm() {
            // Validation checks
            if (!this.receipt.client) {
                toast({
                    message: 'Proszę wybrać kontrahenta.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 3000,
                    position: 'bottom-right',
                });
                return;
            }

            if (this.receipt.receipt_items.length > 5) {
                for (let i = 0; i < this.receipt.receipt_items.length; i++) {
                    const item = this.receipt.receipt_items[i]

                    if (!item.name || !item.buy_price || !item.quantity) {
                        toast({
                            message: 'Proszę wypełnić wszystkie pola dla produktów.',
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 3000,
                            position: 'bottom-right',
                        });
                        return;
                    }
                }
            }

            for (let i = 0; i < this.receipt.receipt_items.length; i++) {
                if (this.receipt.receipt_items[i].name.name == "Złom >10" || 
                    this.receipt.receipt_items[i].name.name == "Złom >4" || 
                    this.receipt.receipt_items[i].name.name == "Złom cienki" || 
                    this.receipt.receipt_items[i].name.name == "Złom gabaryt" || 
                    this.receipt.receipt_items[i].name.name == "Złom wióra stalowe" || 
                    this.receipt.receipt_items[i].name.name == "Złom Żeliwa") {
                // console.log(this.receipt.receipt_items[i].quantity)
                this.receipt.receipt_items[i].quantity = Math.round(this.receipt.receipt_items[i].quantity * 0.98 * 100) / 100
                this.receipt.receipt_items[i].gross_amount = Math.round(this.receipt.receipt_items[i].quantity * this.receipt.receipt_items[i].buy_price * 100) / 100
                }
            }

            for (let i = this.receipt.receipt_items.length - 1; i >= 0; i--) {
                if (this.receipt.receipt_items[i].name.name === undefined) {
                    this.receipt.receipt_items.splice(i, 1)
                    // console.log(this.receipt.receipt_items)
                }
            }

            if (this.receipt.receipt_items.length < 15) {
                for (let i = 0; i < (15 - this.receipt.receipt_items.length); i++) {
                    this.receipt.receipt_empty_items.push({
                        item_num: this.receipt.receipt_items.length + i
                    })
                }
            }

            this.receipt.client_name = this.receipt.client.name
            this.receipt.client_email = this.receipt.client.email
            this.receipt.client_doc_number = this.receipt.client.doc_number
            this.receipt.client_address1 = this.receipt.client.address1
            this.receipt.client_zipcode = this.receipt.client.zipcode
            this.receipt.client_place = this.receipt.client.place
            this.receipt.client = this.receipt.client.id

            let selectedItemName = ''
            for (let i = 0; i < this.receipt.receipt_items.length; i++) {
                selectedItemName = this.receipt.receipt_items[i].name.name
                this.receipt.receipt_items[i].name = selectedItemName
            }

            // update Totals
            let total_gross_amount = 0
            let total_quantity = 0

            for (let i = 0; i < this.receipt.receipt_items.length; i++) {
                // console.log(this.receipt.receipt_items[i].gross_amount)
                if (this.receipt.receipt_items[i].gross_amount === undefined) {
                    total_gross_amount += 0
                } else {
                    total_gross_amount += this.receipt.receipt_items[i].gross_amount
                }
                // console.log(total_gross_amount)
                if (this.receipt.receipt_items[i].quantity === '') {
                    total_quantity += 0
                } else {
                    total_quantity += this.receipt.receipt_items[i].quantity
                }
            }

            this.receipt.gross_amount = Math.round(total_gross_amount * 100) / 100
            this.receipt.total_quantity = Math.round(total_quantity * 100) / 100

            axios
                .post('/api/v1/receipts/', this.receipt)
                .then(response => {
                    const newReceiptID = response.data.id
                    toast({
                        message: 'Kwit został dodany',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })

                    this.$router.push(`/dashboard/receipts/${newReceiptID}`)
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                    toast({
                        message: 'Wystąpił błąd podczas dodawania kwitu. Spróbuj ponownie.',
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 3000,
                        position: 'bottom-right',
                    })
                })
        },
        async getPdf() {
            // Validation checks
            if (!this.receipt.client) {
                toast({
                    message: 'Proszę wybrać kontrahenta.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 3000,
                    position: 'bottom-right',
                });
                return;
            }

            if (this.receipt.receipt_items.length > 5 ) {
                for (let i = 0; i < this.receipt.receipt_items.length; i++) {
                const item = this.receipt.receipt_items[i]

                    if (!item.name || !item.buy_price || !item.quantity) {
                        toast({
                            message: 'Proszę wypełnić wszystkie pola dla produktów.',
                            type: 'is-danger',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 3000,
                            position: 'bottom-right',
                        });
                        return;
                    }
                }
            }

            for (let i = 0; i < this.receipt.receipt_items.length; i++) {
                // console.log(this.receipt.receipt_items[i].name.name)
                if (this.receipt.receipt_items[i].name.name == "Złom >10" || 
                    this.receipt.receipt_items[i].name.name == "Złom >4" || 
                    this.receipt.receipt_items[i].name.name == "Złom cienki" || 
                    this.receipt.receipt_items[i].name.name == "Złom gabaryt" || 
                    this.receipt.receipt_items[i].name.name == "Złom wióra stalowe" || 
                    this.receipt.receipt_items[i].name.name == "Złom Żeliwa") {
                this.receipt.receipt_items[i].quantity = Math.round(this.receipt.receipt_items[i].quantity * 0.98 * 100) / 100
                this.receipt.receipt_items[i].gross_amount = Math.round(this.receipt.receipt_items[i].quantity * this.receipt.receipt_items[i].buy_price * 100) / 100
                }
            }

            for (let i = this.receipt.receipt_items.length - 1; i >= 0; i--) {
                if (this.receipt.receipt_items[i].name.name === undefined) {
                    this.receipt.receipt_items.splice(i, 1)
                    // console.log(this.receipt.receipt_items)
                }
            }

            if (this.receipt.receipt_items.length < 15) {
                for (let i = 0; i < (15 - this.receipt.receipt_items.length); i++) {
                    this.receipt.receipt_empty_items.push({
                        item_num: this.receipt.receipt_items.length + i
                    })
                }
            }

            this.receipt.client_name = this.receipt.client.name
            this.receipt.client_email = this.receipt.client.email
            this.receipt.client_doc_number = this.receipt.client.doc_number
            this.receipt.client_address1 = this.receipt.client.address1
            this.receipt.client_zipcode = this.receipt.client.zipcode
            this.receipt.client_place = this.receipt.client.place
            this.receipt.client = this.receipt.client.id

            let selectedItemName = ''
            for (let i = 0; i < this.receipt.receipt_items.length; i++) {
                selectedItemName = this.receipt.receipt_items[i].name.name
                this.receipt.receipt_items[i].name = selectedItemName
            }

            // update Totals
            let total_gross_amount = 0
            let total_quantity = 0

            for (let i = 0; i < this.receipt.receipt_items.length; i++) {
                // console.log(this.receipt.receipt_items[i].gross_amount)
                if (this.receipt.receipt_items[i].gross_amount === undefined) {
                    total_gross_amount += 0
                } else {
                    total_gross_amount += this.receipt.receipt_items[i].gross_amount
                }
                // console.log(total_gross_amount)
                if (this.receipt.receipt_items[i].quantity === '') {
                    total_quantity += 0
                } else {
                    total_quantity += this.receipt.receipt_items[i].quantity
                }
            }

            this.receipt.gross_amount = Math.round(total_gross_amount * 100) / 100
            this.receipt.total_quantity = Math.round(total_quantity * 100) / 100

            await axios
                .post('/api/v1/receipts/', this.receipt)
                .then(response => {
                    const newReceiptID = response.data.id
                    this.receiptID = newReceiptID
                    toast({
                        message: 'Kwit został dodany',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                    toast({
                        message: 'Wystąpił błąd podczas dodawania kwitu. Spróbuj ponownie.',
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 3000,
                        position: 'bottom-right',
                    })
                })

            await axios
                .get(`/api/v1/receipts/${this.receiptID}/generate_pdf/`, {
                    responseType: 'blob',
                }).then(res => {
                    fileDownload(res.data, `kwit_${this.receipt.receipt_number}.pdf`);
                }).catch(err => {
                    console.log(err);
                })

            this.$router.push(`/dashboard/receipts/${this.receiptID}`)
        },
        addedClient(newClient) {
            if (newClient && newClient.id) {
                this.clients.push(newClient)  // Add the new client to the clients array
                this.receipt.client = newClient  // Set the new client as the selected client
            } else {
                console.error('Invalid client data received:', newClient)
                // Optionally, show an error message to the user
                toast({
                    message: 'Error adding new client. Please try again.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 3000,
                    position: 'bottom-right',
                })
            }
        },
        openEditClientModal(clientID) {
            this.selectedClientID = clientID
            this.showEditClientModal = true
        },
    }
}
</script>